import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { map } from "rxjs/operators";
import { ReportAssignment } from "@modules/reports/models/assignment.modal";

@Injectable()
export class ReportsService {
  constructor(private http: HttpClient) {}

  public costs(params): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "reports/costs", {
      params,
    });
  }

  public openCasesForUsers(params = {}): Observable<any> {
    return this.http
      .get<any>(environment.gateway_endpoint + "reports/open-cases", {
        params,
      })
      .pipe(
        map((res) =>
          res.result.map((item) => ({
            x: item.user.first_name + " " + item.user.last_name,
            y: item.number_of_cases,
            image:
              item.user.primary_image_path ??
              `https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=${item.user.first_name} ${item.user.last_name}`,
          }))
        )
      );
  }

  public openCasesForProviders(params = {}): Observable<any> {
    return this.http
      .get<any>(environment.gateway_endpoint + "reports/open-cases/providers", {
        params,
      })
      .pipe(
        map((res) =>
          res.result.map((item) => ({
            x: item.company_name,
            y: item.number_of_cases,
            image:
              item.primary_user.user.primary_image_path ??
              `https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=${item.primary_user.user.first_name} ${item.primary_user.user.last_name}`,
          }))
        )
      );
  }

  public assignmentsAboutToEnd(): Observable<any> {
    return this.http
      .get<any>(environment.gateway_endpoint + "reports/assignment-about-end")
      .pipe(
        map((res) =>
          res.result.items.map((item: ReportAssignment) => ({
            y: item.value,
          }))
        )
      );
  }

  public milestoneFilters(params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "reports/milestones/filters",
      {
        params,
      }
    );
  }

  public milestones(params = {}): Observable<any> {
    const parseParams = (data) => {
      let params: HttpParams = new HttpParams();
      for (const key of Object.keys(data)) {
        if (data[key]) {
          if (data[key] instanceof Array) {
            params = params.append(key, data[key].join(";"));
          } else {
            params = params.append(key.toString(), data[key]);
          }
        }
      }
      return params;
    };
    return this.http.get<any>(
      environment.gateway_endpoint + "reports/milestones",
      {
        params: parseParams(params),
      }
    );
  }

  public export(params = {}): Observable<any> {
    return this.http.get(
      `${environment.gateway_endpoint}reports/milestones/export`,
      {
        responseType: "blob",
        params: params,
      }
    );
  }
}
